.chat-container {
    display: flex;
    flex-direction: column;
    height: 90vh; /* Adjust height as needed */
    width: 100%;
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    box-sizing: border-box;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative; /* Set relative positioning for the icon */
}

.chat-window {
    flex: 1;
    overflow-y: auto;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    margin-bottom: 10px;
    position: relative; /* Set relative positioning for the pseudo-element */
}

.chat-window::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url('HAI.png'); /* Adjust image path */
    background-size: 560px 640px; /* Smaller background image */
    background-position: bottom right; /* Align image to bottom-right */
    background-repeat: no-repeat;
    opacity: 0.05; /* Lower opacity for watermark effect */
    z-index: 1;
    pointer-events: none; /* Allow clicks through the image */
}

.message {
    margin-bottom: 10px;
    padding: 10px 15px;
    border-radius: 15px;
    max-width: 80%;
    word-wrap: break-word;
    white-space: pre-wrap;
    position: relative;
    z-index: 2; /* Keep text on top */
    font-weight: normal;
    color: #333;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.HAI {
    background-color: #e0f7fa;
    align-self: flex-start;
    margin-left: 10px;
}

.Jeg {
    background-color: #c8e6c9;
    align-self: flex-end;
    margin-right: 10px;
}

.input-container {
    display: flex;
}

.message-input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px 0 0 5px;
    outline: none;
    font-size: 16px;
}

.send-button {
    padding: 10px 20px;
    border: none;
    background-color: #4caf50;
    color: #fff;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
    font-size: 16px;
}

.send-button:hover {
    background-color: #45a049;
}

.typing-indicator {
    font-style: italic;
    color: #888;
}

.horse-block {
    margin-bottom: 10px;
}

.horse-block p {
    margin: 0;
}

.icon {
    position: absolute;
    top: 25px;
    right: 20px;
    width: 50px; /* Adjust size as needed */
    height: 50px;
    background-image: url('HAI.png'); /* Path to the same image or another */
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.5; /* Lower opacity for watermark effect */
    z-index: 3; /* Keep it on top of the chat window */
}

@media (max-width: 600px) {
    .chat-container {
        height: 100vh;
        border-radius: 0;
        box-shadow: none;
    }

    .message-input {
        font-size: 14px;
    }

    .send-button {
        padding: 10px;
        font-size: 14px;
    }
}
