/* General container styling */
.login-container {
    text-align: center;
    margin: 0 auto;
    padding: 20px;
    max-width: 700px;
    font-family: Arial, sans-serif;
    background-color: #f8f8f8; /* Light gray background */
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Header styling */
h1 {
    font-size: 2.5em;
    margin-bottom: 10px;
    color: #333;
}

p {
    font-size: 1.1em;
    margin-bottom: 20px;
    color: #555;
}

/* Button group styling */
.button-group {
    margin: 20px 0;
}

.vipps-section {
    margin-bottom: 15px;
}

.vipps-section p {
    margin: 0;
    font-size: 1.2em;
    font-weight: bold;
    color: #444;
}

.vipps-button {
    display: inline-block;
    margin: 5px auto;
    padding: 10px 20px;
    font-size: 1em;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.vipps-button:hover {
    background-color: #0056b3;
}

/* Info section styling */
.info-section {
    margin: 30px 0;
    text-align: left;
    font-size: 1em;
    color: #333;
}

.info-section h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
}

.info-section p {
    line-height: 1.6;
}

/* Video section styling */
.video-section {
    margin: 20px 0;
}

.embedded-video {
    width: 100%;
    max-width: 500px;
    border: 1px solid #ccc;
    border-radius: 10px;
}

/* Footer links styling */
.footer-links {
    margin-top: 30px;
    font-size: 0.9em;
}

.footer-links a {
    color: #007bff;
    text-decoration: none;
}

.footer-links a:hover {
    text-decoration: underline;
}


